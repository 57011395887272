exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contactpages-tsx": () => import("./../../../src/pages/contactpages.tsx" /* webpackChunkName: "component---src-pages-contactpages-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-rooms-floor-1-tsx": () => import("./../../../src/pages/rooms/floor1.tsx" /* webpackChunkName: "component---src-pages-rooms-floor-1-tsx" */),
  "component---src-pages-rooms-floor-2-tsx": () => import("./../../../src/pages/rooms/floor2.tsx" /* webpackChunkName: "component---src-pages-rooms-floor-2-tsx" */),
  "component---src-pages-rooms-floor-3-tsx": () => import("./../../../src/pages/rooms/floor3.tsx" /* webpackChunkName: "component---src-pages-rooms-floor-3-tsx" */),
  "component---src-pages-rooms-index-tsx": () => import("./../../../src/pages/rooms/index.tsx" /* webpackChunkName: "component---src-pages-rooms-index-tsx" */)
}

